// Dependencies
import React from "react"
// Components
import Meta from "../components/Meta"
// CSS
import "../css/styles.scss"

const Layout = ({ children, onMouseDown, onMouseMove }) => {
	
	return (	
		<main>
			<Meta />
			<div
				className="guttersx2 nocursor"
				onMouseDown={onMouseDown}
				onMouseMove={onMouseMove}
				style={{ overflowX: 'hidden' }}
			>
				{children}
			</div>
		</main>
	)
}

export default Layout
